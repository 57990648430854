#app-wrapper {
  border: 10px solid $dark;
  padding: 1rem 0;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.content-padding {
  padding: 0 $spacer * 2;
}

@media (max-width: 1024px) {
  #app-wrapper {
    border: none;
    padding: 0;
    overflow-x: auto;
    height: auto;
  }

  .content-padding {
    padding: 0;
  }
}

.tag-round {
  border-radius: 25px;
  font-size: 0.7rem;
  font-family: arial;
}
