#header {
  height: 5vh;
}

.collection-selection {
  padding-top: 1vh !important;
  padding-bottom: 3.5vh !important;

  select {
    border: 2px solid $dark;
    border-radius: 5px;
  }

  select, option {
    font-family: $alt-font-text;
    padding: 10px 15px;
  }
}

@media (max-width: 1024px) {
  #header {
    height: auto;
  }

  .collection-selection {
    height: auto;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;

    p, svg {
      display: none;
    }
  }


  select, option {
    font-family: $alt-font-text;
    padding: 5px 5px !important;
  }
}