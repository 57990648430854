#art--mobile {
  display: none;
}

#art--desktop {
  display: block;
}

.art-piece {
  height: 29vh;

  .art-piece__image {
    position: relative;

    img {
      animation: expandImage 1s forwards;
      max-height: 72vh;
      max-width: 50vw;
      position: absolute;
      margin-left: 4rem;
      -webkit-box-shadow: 40px 40px 0px 0px rgba(0, 0, 0, 0.04);
      -moz-box-shadow: 40px 40px 0px 0px rgba(0, 0, 0, 0.04);
      box-shadow: 40px 40px 0px 0px rgba(0, 0, 0, 0.04);
    }
  }

  .art-piece__details {
    -webkit-animation: fadeIn 1s forwards; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn 1s forwards; /* Firefox < 16 */
    -ms-animation: fadeIn 1s forwards; /* Internet Explorer */
    -o-animation: fadeIn 1s forwards; /* Opera < 12.1 */
    animation: fadeIn 1s forwards;
    margin-left: 2rem;

    p {
      font-size: 1.3rem;
    }

    h1 {
      padding: 0;
      margin: 0;
      line-height: 36px;
    }
  }

  .art-piece__year {
    font-size: 1.5rem;
  }
}

.art-piece, .art-piece--small {
  //padding-bottom: 3rem;

  .row {
    margin-right: 0px;
    margin-left: 0px;
    height: 100%;
  }

  * {
    transition: .5s;
  }
}

.art-piece__timeline {
  padding: 0;
  display: flex;
  justify-content: flex-start;

  .timeline__index {
    margin-right: 20px;
    width: 15px;
    font-size: .9rem;
    font-weight: bold;
    font-style: italic;
  }

  .timeline__dots {
    border-left: 2px #808080 dotted;
  }

  .timeline__point {
    font-size: 16px;
    margin-left: -8px;
    background-color: white
  }
}

.art-piece--small {
  height: 14vh;

  p {
    font-size: .8rem;
    font-weight: bold;
    font-style: italic;
  }
}

.art-piece--final {
  .timeline__dots {
    border-left: none;
  }

  p {
    font-size: 1rem;
    font-weight: bold;
    font-style: normal;
  }
}


@keyframes expandImage {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04);
    margin-left: 3rem;
  }
  to {
    -webkit-box-shadow: 40px 40px 0px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 40px 40px 0px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 40px 40px 0px 0px rgba(0, 0, 0, 0.04);
    margin-left: 4rem;
  }
}


// Fade in -------------------------------------------
@keyframes fadeIn {
  from {
    margin-left: 0;
    opacity: 0;
  }
  to {
    margin-left: 3vh;
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {
    margin-bottom: 1vh;
    opacity: 0;
  }
  to {
    margin-bottom: 0vh;
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {
    margin-bottom: 1vh;
    opacity: 0;
  }
  to {
    margin-bottom: 0vh;
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    margin-bottom: 1vh;
    opacity: 0;
  }
  to {
    margin-bottom: 0vh;
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
  from {
    margin-bottom: 1vh;
    opacity: 0;
  }
  to {
    margin-bottom: 0vh;
    opacity: 1;
  }
}


@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  #art--mobile {
    display: block;
  }

  #art--desktop {
    display: none;
  }

  .art-piece__timeline {
    display: flex;
    justify-content: center;

    .timeline__index {
      margin-right: 20px;
      width: 15px;
      font-size: .9rem;
      font-weight: bold;
      font-style: italic;
    }

    .timeline__dots {
      border-left: 2px #808080 dotted;
    }

    .timeline__point {
      font-size: 16px;
      margin-left: -8px;
      background-color: white
    }
  }

  .art-piece {
    height: auto;
    overflow: hidden;
    margin-bottom: 5vh;

    .art-piece__image img {
      max-height: 100% !important;
      max-width: 100%;
      width: auto !important;
      height: auto !important;
      position: relative;
      margin-left: 0 !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      margin: 0 0 1rem 0;
    }
  }

  .art-piece__details {
    -webkit-animation: none !important; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: none !important; /* Firefox < 16 */
    -ms-animation: none !important; /* Internet Explorer */
    -o-animation: none !important; /* Opera < 12.1 */
    animation: none !important;
    margin-left: 0 !important;

    p {
      font-size: .8rem !important;
      margin-bottom: 1rem;
    }

    h1 {
      padding: 0;
      margin: 0;
      line-height: normal !important;
      font-size: 1.2rem !important;
    }
  }

  .art-piece .art-piece__year {
    font-size: 1rem !important;
  }

  .art-piece, .art-piece--small {
    .row {
      height: auto;
    }

  }
}

.closeTag {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0;
  }
}