.backtotop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  animation: fadeInOpacity .5s forwards;
  z-index: 150;

  svg {
    font-size: 3rem;
  }
}

@keyframes fadeInOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image--hidden {
  display: none;
}

div.image--selected {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 20px);
  width: calc(100vw - 20px);
  top: 0;
  left: 0;
  background: rgba($dark, 0.8);
  z-index: 50;

  .art-piece__image {
    display: flex;
    justify-content: center;

    img {
      max-height: 95vh;
      max-width: 95vw;
      position: relative;
      margin-left: 0 !important;
      box-shadow: none !important;
    }
  }
}

@media (max-width: 1024px) {
  div.image--selected {
    position: fixed;
    height: 100vh;
    width: calc(100vw - 20px);
    .art-piece__image {
      width: 100%;
      height: 100%;
      align-items: center;

      img {
        max-height: 95vh;
        max-width: 95vw;
        width: 100% !important;
      }
    }
  }
}