* {
  font-family: $font-text;
}

h1 {
  font-weight: bold;
}

p {
  font-family: $alt-font-text;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 1.5rem;
  }
}
