#main_menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($dark, .7);
  z-index: 100;
  padding: 2rem;
}

#main_menu nav {
  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    color: $light;
    font-size: 5rem;
  }
}

.main_menu--close {
  color: $light;
  svg {
    margin-right: 1rem;
  }
}